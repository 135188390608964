import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './App.css';

function App() {
  const [isHammerSpinning, setIsHammerSpinning] = useState(false);
  const [isSizeChanging, setIsSizeChanging] = useState(false);
  const [rockets, setRockets] = useState([]); // Track rockets for animation

  // Toggle the hammer spinning effect on hammer click
  const handleHammerClick = () => {
    setIsHammerSpinning(!isHammerSpinning);
  };

  // Toggle the size changing effect on "Coming soon!" click and trigger rocket launch
  const handleSizeChangeClick = () => {
    setIsSizeChanging(true);

    // Add a new rocket to the list for animation
    const newRocket = <img src="/rocket.png" alt="Rocket" className="rocket" key={Date.now()} />;
    setRockets((prevRockets) => [...prevRockets, newRocket]);

    // Reset the size change after the animation completes (3s)
    setTimeout(() => {
      setIsSizeChanging(false);
    }, 3000); // Adjust to match the animation duration

    // Remove rockets after animation completes (3s)
    setTimeout(() => {
      setRockets((prevRockets) => prevRockets.slice(1)); // Remove the oldest rocket
    }, 3000); // Match the rocket animation duration
  };

  return (
    <Container className="container text-center mt-5">
      <Row className="justify-content-center">
        <Col xs={12} md={8}>
          {/* Header Section */}
          <header className="my-5">
            <img
              src="/hammer.png"
              alt="DeFi RPG Icon"
              className={`header-icon ${isHammerSpinning ? 'spin' : ''}`} /* Apply spin class on click */
              onClick={handleHammerClick} /* Add click handler for hammer */
            />
            <h1>DeFi RPG</h1>
          </header>

          {/* Flashy "Coming Soon" Section */}
          <div 
            className={`flashy-text ${isSizeChanging ? 'expand' : ''}`} 
            onClick={handleSizeChangeClick} /* Add click handler */
          >
            Coming soon!
          </div>
          <p></p>

          {/* Links Section */}
          <p>
            Check out our Socials:
          </p>
          <p>
            <a href="discord.gg/c8YefT94dx">Discord Server</a>
          </p>
          <p>
            <a href="https://x.com/defi_rpg">X: DeFi_RPG</a>
          </p>

          {/* Render Rockets */}
          {rockets}
        </Col>
      </Row>

      {/* Footer Section */}
      <footer className="footer">
        <p>© 2024 DeFi RPG. All rights reserved.</p>
        <p>Join us on <a href="discord.gg/c8YefT94dx">Discord</a>!</p>
      </footer>
    </Container>
  );
}

export default App;
